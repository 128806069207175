<template>
  <div>
    <div class="isCentered isSpinner text-center" v-if="isLoadingData">
      <b-spinner class="isCentered" label="Spinning"></b-spinner> <br>
      {{ loaderMessage }}
    </div>
    <div class="" v-if="!dataLength && !isLoadingData && afterSearch">
      <b-alert variant="warning" :show="true" class="mb-0">
        <div class="alert-body"> <feather-icon icon="InfoIcon" class="mr-50" />
          <span><strong>¡Sin registros!</strong> No se encontraron registros.</span>
        </div>
      </b-alert>
    </div>
    <div class="" v-else-if="!dataLength && !isLoadingData">
      <b-alert variant="warning" :show="true" class="mb-0">
        <div class="alert-body"> <feather-icon icon="InfoIcon" class="mr-50" />
          <span>Haga una busqueda mediante el filtro</span>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLoadingData: {
      type: Boolean,
      required: true
    },
    dataLength: {
      type: Number,
      required: true
    },
    afterSearch: {
      type: Boolean,
      required: false
    },
    loaderMessage: {
      type: String,
      default: 'Cargando...'
    }
  }

}
</script>