<template>
    <b-card>
        <b-overlay :show="isLoadingInitData" rounded class="">
            <Filters
                :filterData="filterData"
                :showBenefitFilter="true"
                :showYearFilter="false"
                @look-per-filter="filteredSearch"
            />
        </b-overlay>
        <br>

        <div class="d-flex justify-content-end">
            <div class="head-btn">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-if="can('fivesclub_profit_allow_monetization_configuration_creation')"
                    variant="primary"
                    :to="{name: 'monetizing-config-create', params: {hotelId: filterData.hotelId, benefitId: filterData.benefitId}}"
                    v-b-tooltip.hover.left="'Agregar'"
                >
                    <feather-icon icon="PlusIcon" style="color: white" />
                </b-button>
            </div>
        </div>
        <br>

        <b-table
            v-if="benefitsMonetizationConfig.length && !isLoadingSomething"
            :items="benefitsMonetizationConfig"
            :fields="tableColumns"
            responsive
            class="mb-0"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            :busy.sync="isLoadingMonetizingConfig"
            show-empty
            empty-text="No hay datos para mostrar"
        >
            <template #table-busy>
            <div class="text-center my-2">
                <b-spinner class="isCentered" label="Spinning"></b-spinner> <br>Cargando configuración de monetización de beneficios
            </div>
            </template>
            <template #cell(status)="row">
                <span class="text-nowrap">
                    <b-badge pill :variant="row.item.status ? 'success': 'danger'" >
                    {{ row.item.status ? "Activo" : "Desactivado" }}
                    </b-badge>
                </span>
            </template>
            <template #cell(actions)="row">
                <div>
                    <b-button
                        size="sm"
                        v-if="can('fivesclub_profit_show_monetization_configuration_detail_view')"
                        :to="{ name: 'monetizing-config-edit',  params: { id: row.item.id }}"
                        variant="primary"
                        class="mr-1"
                        title="Editar"
                    > <feather-icon icon="Edit2Icon" size="12" />
                    </b-button>
                </div>
            </template>
        </b-table>

        <EmptyData
            v-else
            :isLoadingData="isLoadingSomething"
            :dataLength="benefitsMonetizationConfig.length"
            :afterSearch="afterSearch"
            loaderMessage="Cargando configuración de monetización de beneficios"
        />
    </b-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { utils } from "@/modules/fivesClub/mixins/utils"
import Filters from '@/modules/fivesClub/components/profit/performanceConcepts/Filters'
import EmptyData from '@/modules/fivesClub/components/profit/EmptyData'
import PaginationTable from '@/modules/fivesClub/components/profit/costs/PaginationTable'
import Ripple from 'vue-ripple-directive'
import { acl } from "@/modules/auth/mixins/acl"

export default {
    mixins: [utils, acl],
    directives: { Ripple, },
    components:{
        PaginationTable,
        Filters,
        EmptyData
    },
    data() {
        return {
            perPage: 100,
            currentPage: 1,
            perPageOptions: [50, 100, 150, 200, 250, 500, 1000],
            sortBy: 'name',
            isSortDirDesc: true,
            tableColumns: [
                { key: "hotelName", label: "Hotel", class: "text-center" },
                { key: "benefitName", label: "Beneficio", class: "text-center" },
                { key: "rooms_from", label: "Desde", class: "text-center"},
                { key: "rooms_to", label: "Hasta", class: "text-center"},
                { key: "amount", label: "Monto", class: "text-center", formatter: value => { return this.formatDecimal(value, true) } },
                { key: 'currencyCode', label: 'Divisa', class: 'text-center' },
                { key: "status", label:"Status", class: "text-center"},
                { key: 'actions', label: 'Acciones', class: 'text-center' },
            ],
            filterData: {
                hotelId: null,
                benefitId: null,
            },
            isLoadingMonetizingConfig: false,
            isLoadingInitData: false,
            afterSearch: false

        };
    },
    async mounted() {
        await this.init()
    },
    computed: {
        ...mapState('fivesClubProfit',['benefitsMonetizationConfig', 'roomsSubTypes']),
        ...mapState('start',['hotels']),
        ...mapState("fivesClubCatalogs", ['benefitOptions']),
        isLoadingSomething(){
            return this.isLoadingMonetizingConfig || this.isLoadingInitData
        }
    },
    methods: {
        ...mapActions('fivesClubProfit', ['getInitialContentForBenefitsMonetizationConfig', 'fetchBenefitsMonetizationConfig']),
        ...mapMutations('start',['setHotels']),
        ...mapMutations('fivesClubProfit',['setBenefitsMonetizationConfig', 'setCatalogPerformanceConcepts']),
        async init(){
            this.isLoadingInitData = true
            await this.getInitialContentForBenefitsMonetizationConfig({
                benefits: true,
                hotels: !this.hotels.length > 0,
            })
            if (this.$route.params.reload) {
                this.filterData = {
                    hotelId: this.$route.params.hotelId,
                    benefitId: this.$route.params.benefitId,
                },
                await this.filteredSearch(this.filterData)
            }
            this.isLoadingInitData = false
        },

        formatDecimal(value, includeSymbol = false) {
            const numericValue = parseFloat(value);
            if (!isNaN(numericValue) && isFinite(numericValue)) {
                return this.toDecimal(numericValue, includeSymbol);
            } else return value
        },

        toDecimal(number, includeSymbol) {
            if (typeof number !== 'number' || isNaN(number) || !isFinite(number)) {
                throw new Error('Invalid number');
            }

            const formattedValue = number.toLocaleString('es-US', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            const formattedWithSymbol = includeSymbol ? `$${formattedValue}` : formattedValue;
            return formattedWithSymbol;
        },

        async filteredSearch(payload){
            this.isLoadingMonetizingConfig = true
            const result = await this.fetchBenefitsMonetizationConfig(payload)
            this.setBenefitsMonetizationConfig(result)
            this.afterSearch = true
            this.isLoadingMonetizingConfig = false
        },
    },
};
</script>


<style lang="scss" >
@media (max-width: 1280px) {
    .mb-0.table-responsive {
        max-height: 369px;
    }
    .table-responsive th {
        padding: 10px 10px 10px 10px !important;
        font-size: 10px;
    }
    .table-responsive th div {
        font-size: 10px;
    }
    .table-responsive td {
        padding: 5px !important;
        font-size: 11px;
    }
    .buttonInfo button {
        padding: 9px;
    }

    .buttonInfo {
        display: table-cell;
    }
}

.swal-button-yes {
    background-color: #3085d6;
    color: #fff;
    margin-right: 10px;  /* Ajusta la separación a tu preferencia */
}

/* Estilo para el botón de cancelación */
.swal-button-cancel {
    background-color: #d33;
    color: #fff;
}
</style>
