<template>
  <div class="list-filters">
    <div><h4>Filtros</h4></div>

    <validation-observer v-slot="{ invalid }" ref="searchDetailTransactions">
      <b-form @submit.prevent="getPerformanceConcepts">
        <b-row align-v="end">
          <b-col md="3">
            <validation-provider name="Hotel" rules="required">
                <b-form-group slot-scope="{ valid, errors }" label="Hotel">
                  <b-form-select
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="filterData.hotelId"
                    :clearable="false"
                  >
                    <option :value="null" disabled>Seleccione una opción</option>
                    <option v-for="h in hotels" :key="h.id" :value="h.id">
                      {{ h.name }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3" v-if="showBenefitFilter">
            <validation-provider name="Beneficio" rules="">
                <b-form-group slot-scope="{ valid, errors }" label="Beneficio">
                  <b-form-select
                    :state="errors[0] || !valid ? false : null"
                    v-model="filterData.benefitId"
                    :clearable="false"
                  >
                    <option :value="null">Seleccione una opción</option>
                    <option
                      v-for="benefitOption in benefitOptions"
                      :key="benefitOption.id"
                      :value="benefitOption.id"
                    > {{ benefitOption.name }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="2" lg="3" v-if="showYearFilter">
            <b-form-group label="Año">
              <b-form-select
                v-model="filterData.year"
                :options="listyears"
              />
            </b-form-group>
          </b-col>
          <!-- Botones -->
          <b-col md="2" sm="6">
            <b-form-group label="">
              <div>
                <b-button
                  class="mr-1 mt-2"
                  type="submit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  :disabled="invalid || isLoading"
                > Buscar
                </b-button>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {  validYears } from "@/helpers/helpers";
import { mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import { utils } from "@/modules/fivesClub/mixins/utils";

export default {
  directives: {
    Ripple,
  },
  mixins: [utils],
  props: {
    isLoading: {
      type: Boolean,
      required: false,
    },
    filterData: {
      type: Object,
      required: true,
    },
    showYearFilter: {
      type: Boolean,
      default: true
    },
    showBenefitFilter: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      porPagina: this.perPage,
      validYears
    };
  },
  computed: {
    ...mapState('start',['hotels']),
    ...mapState("fivesClubCatalogs", ['benefitOptions']),
    listyears(){
      return validYears()
    },
  },
  methods: {
    getPerformanceConcepts() {
      this.$emit("look-per-filter", this.filterData)
    }
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.isCentered {
  align-content: center;
  align-items: center;
}
.isSpinner {
  padding: 2rem;
}
.filters {
  padding-bottom: 2rem;
}
.paddler {
  padding-left: 1rem;
}

.filters {
  border: solid 1px #b8c2cc;
  padding: 12px 12px 13px 12px !important;
  margin: 0 0 16px 0;
}
</style>